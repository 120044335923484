import * as React from 'react'
import SanityBlock from 'components/common/SanityBlock'

const AdditionalContent = ({ title, _rawDescription }) => (
  <section className="container padding richtext">
    {title && <h2 className="h2">{title}</h2>}
    <SanityBlock body={_rawDescription} />
  </section>
)

export default AdditionalContent
