import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import HeroWithBg from 'components/common/HeroWithBg'
import TextItemList from 'components/common/TextItemList'
import AdditionalContent from 'components/pg-product/AdditionalContent'
import Resources from 'components/common/Resources'
import Team from 'components/common/Team'
import Logos from 'components/pg-product/Logos'

const ProductPg = ({ data }) => {
  const pg = data.sanityProductPg

  return (
    <DefaultLayout {...pg.seo}>
      <HeroWithBg {...pg.hero} />
      {!!pg.textItemList?.items.length && <TextItemList className="padding-y" {...pg.textItemList} />}
      {!!pg.content?._rawDescription && <AdditionalContent {...pg.content} />}
      {!!pg.resources?.resources.length && <Resources {...pg.resources} />}
      {!!pg.content2 && <AdditionalContent {...pg.content2} />}
      {!!pg.team && <Team {...pg.team} />}
      {!!pg.logos && <Logos {...pg.logoList} />}
    </DefaultLayout>
  )
}

export default ProductPg

export const query = graphql`query ProductTemplate($id: String) {
  sanityProductPg(id: {eq: $id}) {
    title
    hero { ...hero }
    textItemList {
      title
      items {
        pretitle
        title
        _rawDescription
      }
    }
    content {
      title
      _rawDescription
    }
    resources {
      title
      resources {
        title
        _rawDescription
      }
    }
    content2 {
      title
      _rawDescription
    }
    team {
      title
      team { ...employees }
    }
    logoList {
      title
      items {
        image { asset { gatsbyImageData(placeholder: NONE, height: 100) } }
        alt
      }
    }
    seo {
      title
      description
    }
  }
}`
