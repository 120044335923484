import * as React from 'react'
import * as css from './Logos.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Logos = ({ title, items }) => (
  <section className="container padding">
    {title && <h2 className="uppercase">{title}</h2>}

    <ul className={css.list}>
      {items?.map((logo, key) => (
        <li key={key}>
          <GatsbyImage
            className={css.logo}
            image={getImage(logo.image.asset)}
            alt={logo.alt}
          />
        </li>
      ))}
    </ul>
  </section>
)

export default Logos
